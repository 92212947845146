import React from 'react';
import './loader.scss';
import { Spinner } from 'reactstrap';

const Loader = ({ style }) => (
  <div className="fallback-spinner" style={style}>
    <div className="loading">
      <Spinner color="primary" />
    </div>
  </div>
);
export default Loader;
