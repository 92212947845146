import { createSlice } from '@reduxjs/toolkit';
import getLogs from '../../../services/logsApi';
import getThreatActors from '../../../services/threatActorsApi';
import getMitigations from '../../../services/mitigationsApi';
import getRiskCountsApi from '../../../services/riskCountsApi';
import getThreatActorsCountByCountryApi from '../../../services/threatActorsCountByCountryApi';
import getDashboardThreatActorsCountsByDateApi from '../../../services/dashboardThreatActorsCountByDateApi';
import getThreatActorsCountsByDateApi from '../../../services/threatActorsCountByDateApi';
import getMitigationsByDateApi from '../../../services/mitigationsByDateApi';
import getUserDataApi from '../../../services/userDataApi';
import getUbuntuSystemApi from '../../../services/ubuntuSystemApi';
import getUbuntuOSUpdatesApi from '../../../services/ubuntuOSUpdatesApi';
import getRDSSystemMetricsApi from '../../../services/rdsSystemMetricsApi';
import getUbuntuSystemMetricsCPUApi from '../../../services/ubuntuSystemMetricsCPU';
import getLogsLoadBalancersApi from '../../../services/loadBalancers/logsLoadBalancersApi';
import getBlockByRulesStatsApi from '../../../services/loadBalancers/blockByRulesStatsApi';
import getCountryWiseStatusApi from '../../../services/loadBalancers/countryWiseStatusApi';
import getAmplifyLogCountsApi from '../../../services/amplifyLogs/amplifyLogCountsApi';
import getAmplifyLogsApi from '../../../services/amplifyLogs/amplifyLogsApi';
import getMitigationsStatsApi from '../../../services/mitigationsStatsApi';
import getThreatActorsLoginApi from '../../../services/threatActorsLoginApi';
import getThreatActorsLoginAttemptApi from '../../../services/threatActorsLoginAttemptApi';

const initialState = {
  tickets: [],
  logs: [],
  logsWatchTypes: [],
  threatActors: [],
  threatActorsLogin: [],
  threatActorsLoginAttempt: [],
  threatActorsCountsByDay: [],
  mitigations: [],
  mitigationsCountsByDay: [],
  homeRisks: [],
  homeThreatActorCountsByDay: [],
  homeThreatActorCountsCountrys: [],
  homeMitigations: [],
  calendarDates: [],
  isLoading: false,
  mapLatLon: [],
  assetTypes: [],
  riskLevels: [],
  statusList: [],
  filters: '',
  userInfo: '',
  ubuntuSystemMetrics: [],
  ubuntuOSUpdates: [],
  rdsSystemMetrics: [],
  ubuntuSystemMetricsCPU: [],
  totalPages: [],
  clients_info: [],
  clientSelected: '',
  logsLoadBalancersArray: [],
  blockedByRuleStats: '',
  loadBalancersCountrys: [],
  serverSystemName: '',
  serverNodeName: '',
  amplifyLogCounts: '',
  amplifyLogs: [],
  mitigationsStats: [],
};

export const rdSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    getTickets: (state, action) => {
      state.tickets = action.payload;
    },
    getLogsList: (state, action) => {
      state.logs = action.payload;
    },
    getLogsWatchTypes: (state, action) => {
      state.logsWatchTypes = action.payload;
    },
    getThreatActorsTable: (state, action) => {
      state.threatActors = action.payload;
    },
    setThreatActorsTableLogin: (state, action) => {
      state.threatActorsLogin = action.payload;
    },
    setThreatActorsTableLoginAttempt: (state, action) => {
      state.threatActorsLoginAttempt = action.payload;
    },
    getThreatActorsCountsByDay: (state, action) => {
      state.threatActorsCountsByDay = action.payload;
    },
    getMitigationsList: (state, action) => {
      state.mitigations = action.payload;
    },
    getMitigationsCountsByDay: (state, action) => {
      state.mitigationsCountsByDay = action.payload;
    },
    getHomeRisks: (state, action) => {
      state.homeRisks = action.payload;
    },
    getCalendarDates: (state, action) => {
      state.calendarDates = action.payload;
    },
    getHomeThreatActorCountsByDay: (state, action) => {
      state.homeThreatActorCountsByDay = action.payload;
    },
    getHomeThreatActorCountsCountrys: (state, action) => {
      state.homeThreatActorCountsCountrys = action.payload;
    },
    getHomeMitigations: (state, action) => {
      state.homeMitigations = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    getMapLatLongs: (state, action) => {
      state.mapLatLon = action.payload;
    },
    getAssetTypes: (state, action) => {
      const allOption = { label: 'All', value: 'all' };

      const assetTypesArray = action.payload.map((item) => ({
        label: item.asset_name,
        value: item.asset_type_name,
      }));
      state.assetTypes = [allOption, ...assetTypesArray];
    },
    setRiskLevels: (state, action) => {
      const allOption = { label: 'All', value: 'all' };

      const riskLevelsArray = action.payload.map((item) => ({
        label: item.charAt(0).toUpperCase() + item.slice(1),
        value: item,
      }));
      state.riskLevels = [allOption, ...riskLevelsArray];
    },
    setStatusList: (state, action) => {
      const allOption = { label: 'All', value: 'all' };

      const statusArray = action.payload.map((item) => ({
        label: item,
        value: item,
      }));
      state.statusList = [allOption, ...statusArray];
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    getUbuntuSystemMetrics: (state, action) => {
      state.ubuntuSystemMetrics = action.payload;
    },
    getUbuntuOSUpdates: (state, action) => {
      state.ubuntuOSUpdates = action.payload;
    },
    getRDSSystemMetrics: (state, action) => {
      state.rdsSystemMetrics = action.payload;
    },
    getUbuntuSystemMetricsCPU: (state, action) => {
      state.ubuntuSystemMetricsCPU = action.payload;
    },
    getTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setClientsInfo: (state, action) => {
      state.clients_info = action.payload;
    },
    setClientSelected: (state, action) => {
      state.clientSelected = action.payload;
    },
    getLogsLoadBalancersArray: (state, action) => {
      state.logsLoadBalancersArray = action.payload;
    },
    getBlockByRulesStats: (state, action) => {
      state.blockedByRuleStats = action.payload;
    },
    getLoadBalancersCountrys: (state, action) => {
      state.loadBalancersCountrys = action.payload;
    },
    setServerSystemName: (state, action) => {
      state.serverSystemName = action.payload;
    },
    setServerNodeName: (state, action) => {
      state.serverNodeName = action.payload;
    },
    setAmplifyLogCounts: (state, action) => {
      state.amplifyLogCounts = action.payload;
    },
    setAmplifyLogs: (state, action) => {
      state.amplifyLogs = action.payload;
    },
    setMitigationsStats: (state, action) => {
      state.mitigationsStats = action.payload;
    },
  },
});

export const {
  getTickets,
  getLogsList,
  getLogsWatchTypes,
  getThreatActorsTable,
  setThreatActorsTableLogin,
  setThreatActorsTableLoginAttempt,
  getThreatActorsCountsByDay,
  getMitigationsList,
  getMitigationsCountsByDay,
  getHomeRisks,
  getCalendarDates,
  getHomeThreatActorCountsByDay,
  getHomeThreatActorCountsCountrys,
  getHomeMitigations,
  setIsLoading,
  getMapLatLongs,
  getAssetTypes,
  setRiskLevels,
  setStatusList,
  setFilters,
  setVisibilityFilter,
  setUserInfo,
  getUbuntuSystemMetrics,
  getUbuntuOSUpdates,
  getRDSSystemMetrics,
  getUbuntuSystemMetricsCPU,
  getTotalPages,
  setClientsInfo,
  setClientSelected,
  getLogsLoadBalancersArray,
  getBlockByRulesStats,
  getLoadBalancersCountrys,
  setServerSystemName,
  setServerNodeName,
  setAmplifyLogCounts,
  setAmplifyLogs,
  setMitigationsStats,
} = rdSlice.actions;

export const fetchHomeData =
  (options = {}) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { params } = options;
      const [
        responseRiskCounts,
        responseThreatActorsByDate,
        responseThreatActorsByCountry,
        responseUserData,
      ] = await Promise.all([
        getRiskCountsApi(params),
        getDashboardThreatActorsCountsByDateApi(params),
        getThreatActorsCountByCountryApi(params),
        getUserDataApi(),
      ]);
      dispatch(setUserInfo(responseUserData.user_info));
      dispatch(getHomeRisks(responseRiskCounts.risk));
      dispatch(getHomeMitigations(responseRiskCounts.risk.total));
      dispatch(getHomeThreatActorCountsByDay(responseThreatActorsByDate.threat_actor_counts));
      dispatch(
        getHomeThreatActorCountsCountrys(responseThreatActorsByCountry.threat_actor_counts_country),
      );
      dispatch(
        getCalendarDates({
          start_date: responseThreatActorsByDate.start_date,
          end_date: responseThreatActorsByDate.end_date,
        }),
      );
      dispatch(setIsLoading(false));
    } catch (err) {
      throw new Error(err);
    }
  };

export const fetchThreatActorsData =
  (options = {}) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { params } = options;
      const [responseThreatActors, responseThreatActorsByDate, responseUserData] =
        await Promise.all([
          getThreatActors(params),
          getThreatActorsCountsByDateApi(params),
          getUserDataApi(),
        ]);
      dispatch(setUserInfo(responseUserData.user_info));
      dispatch(getThreatActorsTable(responseThreatActors.threat_actor_list));
      dispatch(getThreatActorsCountsByDay(responseThreatActorsByDate.threat_actor_counts_by_date));
      dispatch(
        getCalendarDates({
          start_date: responseThreatActors.start_date,
          end_date: responseThreatActors.end_date,
        }),
      );
      dispatch(setRiskLevels(responseUserData.user_info.risk_levels));
      dispatch(getTotalPages(responseThreatActors.total_pages));
      dispatch(setIsLoading(false));
    } catch (err) {
      throw new Error(err);
    }
  };

export const fetchThreatActorsLoginData =
  (options = {}) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { params } = options;
      const [responseThreatActorsLogin, responseUserData] = await Promise.all([
        getThreatActorsLoginApi(params),
        getUserDataApi(),
      ]);
      dispatch(setUserInfo(responseUserData.user_info));
      dispatch(setRiskLevels(responseUserData.user_info.risk_levels));
      dispatch(setThreatActorsTableLogin(responseThreatActorsLogin.threat_actor_list));
      dispatch(getTotalPages(responseThreatActorsLogin.total_pages));
      dispatch(
        getCalendarDates({
          start_date: responseThreatActorsLogin.start_date,
          end_date: responseThreatActorsLogin.end_date,
        }),
      );
      dispatch(setIsLoading(false));
    } catch (err) {
      throw new Error(err);
    }
  };

export const fetchThreatActorsLoginAttemptData =
  (options = {}) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { params } = options;
      const [responseThreatActorsLoginAttempt, responseUserData] = await Promise.all([
        getThreatActorsLoginAttemptApi(params),
        getUserDataApi(),
      ]);
      dispatch(setUserInfo(responseUserData.user_info));
      dispatch(setRiskLevels(responseUserData.user_info.risk_levels));
      dispatch(
        setThreatActorsTableLoginAttempt(responseThreatActorsLoginAttempt.threat_actor_list),
      );
      dispatch(getTotalPages(responseThreatActorsLoginAttempt.total_pages));
      dispatch(
        getCalendarDates({
          start_date: responseThreatActorsLoginAttempt.start_date,
          end_date: responseThreatActorsLoginAttempt.end_date,
        }),
      );
      dispatch(setIsLoading(false));
    } catch (err) {
      throw new Error(err);
    }
  };

export const fetchMitigationsData =
  (options = {}) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { params } = options;
      const [
        responseMitigations,
        responseMitigationsByDate,
        responseMitigationsStats,
        responseUserData,
      ] = await Promise.all([
        getMitigations(params),
        getMitigationsByDateApi(params),
        getMitigationsStatsApi(params),
        getUserDataApi(),
      ]);
      dispatch(setMitigationsStats(responseMitigationsStats.stats));
      dispatch(setUserInfo(responseUserData.user_info));
      dispatch(getMitigationsList(responseMitigations.data));
      dispatch(getMitigationsCountsByDay(responseMitigationsByDate.counts));
      dispatch(
        getCalendarDates({
          start_date: responseMitigations.args.start_date,
          end_date: responseMitigations.args.end_date,
        }),
      );
      dispatch(setRiskLevels(responseUserData.user_info.severity_list));
      dispatch(setStatusList(responseUserData.user_info.status_list));
      dispatch(getTotalPages(responseMitigations.total_pages));
      dispatch(setIsLoading(false));
    } catch (err) {
      throw new Error(err);
    }
  };

export const fetchUbuntuSystemMetrics =
  (options = {}) =>
  async (dispatch) => {
    try {
      const { params } = options;
      const [responseUbuntuSystem, responseUserData] = await Promise.all([
        getUbuntuSystemApi(params),
        getUserDataApi(),
      ]);
      dispatch(
        getCalendarDates({
          start_date: responseUbuntuSystem.start_date,
          end_date: responseUbuntuSystem.end_date,
        }),
      );
      dispatch(getUbuntuSystemMetrics(responseUbuntuSystem.ubuntu_system_metrics_data));
      dispatch(getTotalPages(responseUbuntuSystem.total_pages));
      dispatch(setUserInfo(responseUserData.user_info));
    } catch (err) {
      console.error('Error fetching System Metrics:', err);
      throw new Error(err);
    }
  };

export const fetchUbuntuOSUpdates =
  (options = {}) =>
  async (dispatch) => {
    try {
      const { params } = options;
      const [responseOSSystem, responseUserData] = await Promise.all([
        getUbuntuOSUpdatesApi(params),
        getUserDataApi(),
      ]);
      dispatch(getUbuntuOSUpdates(responseOSSystem.ubuntu_os_updates_data.log_list));
      dispatch(setServerSystemName(responseOSSystem.ubuntu_os_updates_data.system_name));
      dispatch(setServerNodeName(responseOSSystem.ubuntu_os_updates_data.node_name));
      dispatch(
        getCalendarDates({
          start_date: responseOSSystem.start_date,
          end_date: responseOSSystem.end_date,
        }),
      );
      dispatch(getTotalPages(responseOSSystem.total_pages));
      dispatch(setUserInfo(responseUserData.user_info));
    } catch (err) {
      throw new Error(err);
    }
  };

export const fetchRDSSystemMetrics =
  (options = {}) =>
  async (dispatch) => {
    try {
      const { params } = options;
      const [responseRDSSystem, responseUserData] = await Promise.all([
        getRDSSystemMetricsApi(params),
        getUserDataApi(),
      ]);
      dispatch(getRDSSystemMetrics(responseRDSSystem.rds_system_metrics_data));
      dispatch(
        getCalendarDates({
          start_date: responseRDSSystem.start_date,
          end_date: responseRDSSystem.end_date,
        }),
      );
      dispatch(getTotalPages(responseRDSSystem.total_pages));
      dispatch(setUserInfo(responseUserData.user_info));
    } catch (err) {
      throw new Error(err);
    }
  };

export const fetchLogs =
  (options = {}) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { params } = options;
      const [responseLogs, responseUserData] = await Promise.all([
        getLogs(params),
        getUserDataApi(),
      ]);
      dispatch(setUserInfo(responseUserData.user_info));
      dispatch(getLogsList(responseLogs.log_list));
      dispatch(getLogsWatchTypes(responseUserData.user_info.watch_types));
      dispatch(
        getCalendarDates({ start_date: responseLogs.start_date, end_date: responseLogs.end_date }),
      );
      dispatch(getTotalPages(responseLogs.total_pages));
      dispatch(setIsLoading(false));
    } catch (err) {
      throw new Error(err);
    }
  };

export const fetchUbuntuMetrics =
  (options = {}) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { params } = options;
      const response = await getUbuntuSystemMetricsCPUApi(params);
      dispatch(getUbuntuSystemMetricsCPU(response.rds_system_metrics_data));
      dispatch(setIsLoading(false));
    } catch (err) {
      throw new Error(err);
    }
  };

export const setClientSelectedApi = (clientSelected) => async (dispatch) => {
  try {
    dispatch(setClientSelected(clientSelected));
  } catch (err) {
    throw new Error(err);
  }
};

export const getAllClientsInfo = (navigate) => async (dispatch) => {
  try {
    const response = await getUserDataApi(navigate);
    dispatch(setClientsInfo(response.user_info.clients_info));
  } catch (err) {
    throw new Error(err);
  }
};

export const getLoadBalancers =
  (options = {}) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { params } = options;
      const [responseLogList, responseUserData, responseBlockByRules, responseCountrys] =
        await Promise.all([
          getLogsLoadBalancersApi(params),
          getUserDataApi(),
          getBlockByRulesStatsApi(params),
          getCountryWiseStatusApi(params),
        ]);
      dispatch(
        getCalendarDates({
          start_date: responseCountrys.start_date,
          end_date: responseCountrys.end_date,
        }),
      );
      dispatch(getLogsLoadBalancersArray(responseLogList.log_list));
      dispatch(getTotalPages(responseLogList.total_pages));
      dispatch(setUserInfo(responseUserData.user_info));
      dispatch(getBlockByRulesStats(responseBlockByRules.blocked_by_rule_stats));
      dispatch(getLoadBalancersCountrys(responseCountrys.country_wise_status.country_list));
      dispatch(setIsLoading(false));
    } catch (err) {
      throw new Error(err);
    }
  };

export const getAmplifyLogs =
  (options = {}) =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { params } = options;
      const [responseLogCounts, responseLogs, responseUserData] = await Promise.all([
        getAmplifyLogCountsApi(params),
        getAmplifyLogsApi(params),
        getUserDataApi(),
      ]);
      dispatch(
        getCalendarDates({
          start_date: responseLogCounts.start_date,
          end_date: responseLogCounts.end_date,
        }),
      );
      dispatch(setAmplifyLogCounts(responseLogCounts.log_counts));
      dispatch(setAmplifyLogs(responseLogs.log_list));
      dispatch(getTotalPages(responseLogs.total_pages));
      dispatch(setUserInfo(responseUserData.user_info));
      dispatch(setIsLoading(false));
    } catch (err) {
      throw new Error(err);
    }
  };

export default rdSlice.reducer;
