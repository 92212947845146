import rootParams from './rootParams';

const getMitigationsStatsApi = async (params = {}) => {
  try {
    const headers = new Headers();
    const token = localStorage.getItem('token');

    if (token) {
      headers.append('Authorization', `Bearer ${token}`);
    }

    if (params.clientSelected) {
      headers.append('ClientId', params.clientSelected);
    }

    const baseUrl = `${rootParams.rootUrl}/mitigations/mitigation-stats`;
    const url = new URL(baseUrl);

    if (params) {
      const queryParams = {};

      if (params.startDate) queryParams.start_date = params.startDate;
      if (params.endDate) queryParams.end_date = params.endDate;
      if (params.assetType) queryParams.asset_type = params.assetType;
      if (params.type) queryParams.threat_actor_type = params.type;

      Object.keys(queryParams).forEach((key) => url.searchParams.append(key, queryParams[key]));
    }

    const response = await fetch(url, {
      method: 'GET',
      headers,
      redirect: 'follow',
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Error ${response.status}: ${errorText}`);
    }
    return response.json();
  } catch (error) {
    console.error('Error fetching mitigations stats:', error);
    throw error;
  }
};

export default getMitigationsStatsApi;
