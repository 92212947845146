import rootParams from './rootParams';

const getRiskCountsApi = async (params) => {
  const myHeaders = new Headers();
  const token = localStorage.getItem('token');
  myHeaders.append('Authorization', `bearer ${token}`);

  if (params.clientSelected) {
    myHeaders.append('ClientId', params.clientSelected);
  }

  const requestOptions = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
  };

  const baseUrl = `${rootParams.rootUrl}dashboard/risk-counts`;
  let url;

  if (params) {
    const queryParams = {};

    if (params.startDate) {
      queryParams.start_date = params.startDate;
    }

    if (params.endDate) {
      queryParams.end_date = params.endDate;
    }

    if (params.assetType) {
      queryParams.asset_type = params.assetType;
    }

    url = `${baseUrl}?${new URLSearchParams(queryParams)}`;
  } else {
    url = baseUrl;
  }

  const response = await fetch(url, requestOptions);

  if (!response.ok) {
    throw new Error('Get risk counts Api failed');
  }

  return response.json();
};

export default getRiskCountsApi;
