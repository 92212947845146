import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/loader/Loadable';
/****Layouts*****/

const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
/***** Pages ****/

const Minimal = Loadable(lazy(() => import('../views/dashboards/Minimal')));
const Logs = Loadable(lazy(() => import('../views/dashboards/Logs')));
const ThreatActors = Loadable(lazy(() => import('../views/dashboards/ThreatActors')));
const ThreatActorsSuccessfulLogin = Loadable(
  lazy(() => import('../views/dashboards/ThreatActorsSuccessfulLogin')),
);
const ThreatActorsFailedLogin = Loadable(
  lazy(() => import('../views/dashboards/ThreatActorsFailedLogin')),
);
const MitigationsInsight = Loadable(lazy(() => import('../views/dashboards/MitigationsInsight')));
const AssetsMatrixes = Loadable(lazy(() => import('../views/dashboards/AssetsMatrixes')));
const UbuntuSystemMetrics = Loadable(lazy(() => import('../views/dashboards/UbuntuSystemMetrics')));
const UbuntuOSUpdates = Loadable(lazy(() => import('../views/dashboards/UbuntuOSUpdates')));
const RDSSystemMetrics = Loadable(lazy(() => import('../views/dashboards/RDSSystemMetrics')));
const LoadBalancers = Loadable(lazy(() => import('../views/dashboards/LoadBalancers')));
const AmplifyLogs = Loadable(lazy(() => import('../views/dashboards/AmplifyLogs')));

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
const RegisterFormik = Loadable(lazy(() => import('../views/auth/RegisterFormik')));
const LoginFormik = Loadable(lazy(() => import('../views/auth/LoginFormik')));
const Maintanance = Loadable(lazy(() => import('../views/auth/Maintanance')));
const LockScreen = Loadable(lazy(() => import('../views/auth/LockScreen')));
const RecoverPassword = Loadable(lazy(() => import('../views/auth/RecoverPassword')));

/*****Routes******/

export const ThemeRoutes = [
  {
    path: '/',
    element: <FullLayout />,
    children: [
      { path: '/', name: 'Home', element: <Navigate to="/auth/signin" /> },
      { path: '*', element: <Navigate to="/auth/signin/keyExpired" /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
      { path: 'registerformik', element: <RegisterFormik /> },
      { path: 'signin', element: <LoginFormik /> },
      { path: 'signin/:keyExpired', element: <LoginFormik /> },
      { path: 'maintanance', element: <Maintanance /> },
      { path: 'lockscreen', element: <LockScreen /> },
      { path: 'recoverpwd', element: <RecoverPassword /> },
    ],
  },
];

export const AuthorizedRoutes = [
  { path: '*', name: 'Universal', element: <Navigate to="/dashboards/home" /> },
  {
    path: '/dashboards/',
    element: <FullLayout />,
    children: [
      { path: 'home', name: 'home', exact: true, element: <Minimal /> },
      { path: 'threat-actors', name: 'threat actors', exact: true, element: <ThreatActors /> },
      {
        path: 'threat-actors/invalid-login',
        name: 'threat actors',
        exact: true,
        element: <ThreatActorsSuccessfulLogin />,
      },
      {
        path: 'threat-actors/invalid-login-attempt',
        name: 'threat actors',
        exact: true,
        element: <ThreatActorsFailedLogin />,
      },
      {
        path: 'mitigations-insight',
        name: 'mitigations insight',
        exact: true,
        element: <MitigationsInsight />,
      },
      {
        path: 'performace-matrixes',
        name: 'performance matrixes',
        exact: true,
        element: <AssetsMatrixes />,
      },
      {
        path: 'performace-matrixes/ubuntu-system',
        name: 'Ubunty system metrics',
        exact: true,
        element: <UbuntuSystemMetrics />,
      },
      {
        path: 'performace-matrixes/ubuntu-os',
        name: 'Ubunty OS Updates',
        exact: true,
        element: <UbuntuOSUpdates />,
      },
      {
        path: 'performace-matrixes/rds-system',
        name: 'RDS System metrics',
        exact: true,
        element: <RDSSystemMetrics />,
      },
      { path: 'logs', name: 'logs', exact: true, element: <Logs /> },
      { path: 'load-balancer', name: 'load balancer', exact: true, element: <LoadBalancers /> },
      { path: 'amplify', name: 'amplify', exact: true, element: <AmplifyLogs /> },
    ],
  },
];
