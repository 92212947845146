import { configureStore } from '@reduxjs/toolkit';
import CustomizerReducer from './customizer/CustomizerSlice';
import RdReducer from './apps/ticket/TicketSlice';

export const store = configureStore({
  reducer: {
    customizer: CustomizerReducer,
    rdReducer: RdReducer,
  },
});

export default store;
