/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ThemeRoutes, AuthorizedRoutes } from './routes/Router';
import ThemeSelector from './layouts/theme/ThemeSelector';
import Loader from './layouts/loader/Loader';
import useAuth from './hooks/useAuth';

const App = () => {
  const { isAuthenticated } = useAuth();
  const direction = useSelector((state) => state.customizer.isRTL);
  const isMode = useSelector((state) => state.customizer.isDark);

  const whichRouting = () => {
    if (!isAuthenticated) return ThemeRoutes;
    return AuthorizedRoutes;
  };
  return (
    <Suspense fallback={<Loader />}>
      <div
        className={`${direction ? 'rtl' : 'ltr'} ${isMode ? 'dark' : ''}`}
        dir={direction ? 'rtl' : 'ltr'}
      >
        <ThemeSelector />
        {useRoutes(whichRouting())}
      </div>
    </Suspense>
  );
};

export default App;
